<template>
  <div class="booking-form">
    <v-card
      v-if="permission.read_perm === 1"
      elevation="2"
      class="booking-form-card-1 mx-auto"
    >
      <v-form ref="entryForm" @submit.prevent="formValidation">
        <v-row>
          <v-col cols="12">
            <v-toolbar flat style="border-radius:10px 10px 0 0;">
              <v-btn
                class="mx-2 elevation-4"
                dark
                large
                color="cyan"
                style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
              >
                <v-icon large dark>
                  mdi-google-classroom
                </v-icon>
              </v-btn>
              <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
              <v-toolbar-title>Make Request</v-toolbar-title>
              <v-spacer />
              <div class="booking-form-card-action">
                <v-btn
                  v-if="permission.create_perm === 1"
                  width="100"
                  large
                  tile
                  elevation="0"
                  type="submit"
                  class="ma-2 green--text"
                  :loading="loading"
                  :disabled="loading"
                >
                  Kirim
                </v-btn>
                <v-btn
                  large
                  width="100"
                  tile
                  elevation="0"
                  type="button"
                  class="ma-2 red--text"
                  :loading="loading"
                  :disabled="loading"
                  @click="cancel"
                >
                  Cancel
                </v-btn>
              </div>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="font-weight-medium text-h1">
              <h3></h3>
            </div>
          </v-col>
        </v-row>
        <v-divider />

        <v-container>
          <v-row>
            <!-- <v-col cols="12" sm="1" md="1"> </v-col> -->
            <v-col cols="12" sm="1" md="3">
              <v-text-field
                label="PIC"
                readonly
                v-model="formHeader.employee_name"
              />
            </v-col>
            <v-col cols="12" sm="1" md="3">
              <v-text-field
                label="Department"
                readonly
                v-model="formHeader.department_name"
              />
            </v-col>
            <v-col cols="12" sm="1" md="5">
              <v-text-field label="Email" readonly v-model="formHeader.email" />
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col cols="12" sm="1" md="11">
              <v-autocomplete
                v-model="paramAPI.building_id"
                :items="getBuildingDropdown"
                :loading="loadingAutocomplete"
                item-text="name"
                item-value="id"
                class="mx-1"
                hide-no-data
                hide-details
                label="Pilih Gedung"
                return-id
                clearable
                :rules="buildingRule"
              />
            </v-col> -->
            <v-col cols="12" sm="1" md="11">
              <v-autocomplete
                v-model="paramAPI.room_id"
                :items="getRoomDropdown"
                :loading="loadingAutocomplete"
                :item-disabled="disableItem"
                item-text="name"
                item-value="id"
                class="mx-1"
                hide-details
                label="Pilih Ruang Meeting"
                return-object
                style="margin-bottom:30px;"
                :rules="roomRule"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="1" md="1"> </v-col>
            <v-col
              v-if="paramAPI.room_id !== null"
              style="padding:0 18px; position:relative; top:-40px;"
              cols="12"
              sm="1"
              md="11"
            >
              <div v-if="paramAPI.room_id.id !== 0">
                <p style="margin:0; font-size: 14px; ">
                  <span style="color:yellow; background:grey;">WARNING!</span>
                  Maximal peserta untuk penggunaan ruang ini adalah
                  <span style="color:red; text-decoration:underline;"
                    >{{ roomCapacity(paramAPI.room_id.id) }}
                  </span>
                  orang
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="1" md="5">
              <v-text-field
                :rules="startRule"
                v-model="paramAPI.start_use"
                type="datetime-local"
                name="datetime"
                step="1"
                label="Mulai Penggunaan"
              />
            </v-col>
            <v-col cols="12" sm="1" md="1"> </v-col>
            <v-col cols="12" sm="1" md="5">
              <v-text-field
                :rules="endRule"
                v-model="paramAPI.end_use"
                type="datetime-local"
                name="datetime"
                step="1"
                label="Selesai Penggunaan"
              />
            </v-col>
            <v-col cols="12" sm="1" md="1"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="1" md="5">
              <v-autocomplete
                :rules="typeRule"
                v-model="paramAPI.meeting_type"
                :items="[
                  { id: 1, name: 'Internal' },
                  { id: 2, name: 'Eksternal' },
                  { id: 3, name: 'Vendor' },
                  { id: 4, name: 'Direksi' },
                  { id: 5, name: 'Lainnya' }
                ]"
                :loading="loadingAutocomplete"
                item-text="name"
                item-value="id"
                cache-items
                class="mx-1"
                hide-no-data
                hide-details
                label="Pilih Jenis Meeting"
                return-object
                style="margin-bottom:30px;"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="1" md="1"> </v-col>
            <v-col cols="12" sm="1" md="2">
              <v-text-field
                type="number"
                v-model="paramAPI.number_of_attendance"
                label="Jumlah Peserta"
                :rules="quantityRule"
              />
            </v-col>
            <v-col cols="12" sm="1" md="3"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" style="padding:0 15px;">
              <v-textarea
                v-model="paramAPI.description"
                outlined
                dense
                clear-icon="mdi-close-circle"
                label="Keterangan"
                style="margin-top:5px; "
                value="Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, ullam? At perspiciatis culpa autem nostrum, assumenda, sequi ea atque deleniti id, nihil soluta reiciendis quae rerum. Dicta minima architecto possimus."
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="1" md="1"> </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <div class="booking-form-card-action-2">
          <v-btn
            large
            tile
            width="100"
            elevation="0"
            type="submit"
            color="green light"
            class="ma-2 white--text"
            :loading="loading"
            :disabled="loading"
          >
            Kirim
          </v-btn>
          <v-btn
            large
            tile
            width="100"
            elevation="0"
            type="button"
            color="pink"
            class="ma-2 white--text"
            :loading="loading"
            :disabled="loading"
            @click="cancel"
          >
            Cancel
          </v-btn>
        </div>
      </v-form>
    </v-card>
    <Loader v-else />
    <div v-if="permission.read_perm === 0" style="width:80%;height:83vh;">
      <AccessDenied />
    </div>
  </div>
</template>
<script>
import Loader from '../../../components/common/loader/CircularProgress'
import AccessDenied from '../../../components/common/accessDenied/accessDenied'
import { mapActions, mapGetters, mapMutations } from 'vuex'
// import axios from 'axios'
export default {
  data() {
    return {
      menu_key: 16,
      permission: {},
      application: {
        id: 9,
        name: 'Room Activity'
      },
      formHeader: {},
      paramAPI: {
        act: 'add',
        room_id: null,
        building_id: 0,
        start_use: '',
        end_use: '',
        meeting_type: null,
        meeting_type_other: '',
        number_of_attendance: 0,
        description: ''
      },
      loading: false,
      loadingAutocomplete: false,

      valid: false,
      startRule: [],
      endRule: [],
      // buildingRule: [],
      roomRule: [],
      typeRule: [],
      quantityRule: []
    }
  },
  components: { AccessDenied, Loader },

  watch: {
    // 'paramAPI.start_use'() {
    //   console.log(this.paramAPI.start_use)
    // },
    // 'paramAPI.end_use'() {
    //   console.log(this.paramAPI.end_use)
    // }
    // 'paramAPI.building_id'() {
    //   const id =
    //     this.paramAPI.building_id !== null ? this.paramAPI.building_id : 0
    //   this.roomDropdown(id)
    // }
  },
  created() {
    this.setIsLoading(true)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            console.log(result)
            this.permission = result.data
            this.setPermission(result.data)
            this.dropdown()
            this.initForm()
            setTimeout(() => {
              this.setIsLoading(false)
            }, 500)
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
              setTimeout(() => {
                this.setIsLoading(false)
              }, 500)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        setTimeout(() => {
          this.setIsLoading(false)
        }, 500)
      }
    }
  },

  computed: {
    ...mapGetters([
      'getRoomDropdown',
      'getUserProfile',
      'getIsLoading'
      // 'getBuildingDropdown'
    ])
  },
  methods: {
    ...mapActions([
      'roomDropdown',
      'requestRoom',
      'menu_access'
      // 'buildingDropdown'
    ]),
    ...mapMutations(['setPermission', 'setIsLoading']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            title:
              pModalType === 'success'
                ? '<strong>Success</strong>'
                : `<strong>${pModalType}</strong>`,
            icon: pModalType,
            html: pStatusMsg,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    disableItem(item) {
      return item.id === 6
    },
    dropdown() {
      // this.buildingDropdown()
      //   .then(res => {
      //     console.log(res)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      this.roomDropdown(this.getUserProfile)
    },
    initForm() {
      this.formHeader = {
        employee_id: this.getUserProfile.employee_id,
        employee_name: this.getUserProfile.employee.name,
        email: this.getUserProfile.employee.email,
        department_id:
          this.getUserProfile.employee.department !== null
            ? this.getUserProfile.employee.department.id
            : 0,
        department_name:
          this.getUserProfile.employee.department !== null
            ? this.getUserProfile.employee.department.name
            : '-',
        company_id:
          this.getUserProfile.employee.company !== null
            ? this.getUserProfile.employee.company.id
            : 0,
        company_name:
          this.getUserProfile.employee.company !== null
            ? this.getUserProfile.employee.company.name
            : '-'
      }
    },

    onSubmitHeader() {
      const newForm = {
        act: 'add',
        room_id: this.paramAPI.room_id.id,
        room_name: this.paramAPI.room_id.name,
        start_use: this.paramAPI.start_use,
        end_use: this.paramAPI.end_use,
        meeting_type: this.paramAPI.meeting_type.id,
        meeting_type_name: this.paramAPI.meeting_type.name,
        meeting_type_other: this.paramAPI.meeting_type_other,
        number_of_attendance: this.paramAPI.number_of_attendance,
        description: this.paramAPI.description,

        employee_id: this.formHeader.employee_id,
        email: this.formHeader.email,
        department_id: this.formHeader.department_id,
        department_name: this.formHeader.department_name,
        company_id: this.formHeader.company_id,
        company_name: this.formHeader.company_name
      }
      this.submit(newForm)
    },
    formValidation() {
      this.startRule = [v => !!v || 'Start time is required']
      this.endRule = [
        v => !!v || 'End time is required',
        v => v > this.paramAPI.start_use || 'Must be more than start time'
      ]
      this.buildingRule = [v => !!v || 'Building is required']
      this.roomRule = [v => !!v || 'Meeting room is required']
      this.typeRule = [v => !!v || 'Meeting type is required']
      this.quantityRule = [v => !!v || 'Number of attendace cannot be 0']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.onSubmitHeader()
        }
      })
    },
    submit(formData) {
      this.permission.read_perm = null
      this.requestRoom(formData)
        .then(res => {
          console.log('Request Success', res)
          this.permission.read_perm = 1
          this.setIsLoading(false)
          if (res.status_code === '00') {
            // const createdId = res.created_id
            // const bookingNo = res.booking_no
            // this.sendNotifToTelegram(formData, bookingNo, createdId)
            this.showMsgDialog('success', res.status_msg, false)
            this.cancel()
          } else {
            this.showMsgDialog('warning', res.status_msg, false)
          }
        })
        .catch(err => {
          console.log('Request Error', err)
          this.permission.read_perm = 1
          this.showMsgDialog('error', err, false)
        })
    },
    cancel() {
      this.resetForm()
      this.$router.push('/meeting-activity/my-booking')
    },
    resetForm() {
      this.formHeader = {
        employee_name: '',
        email: '',
        department_name: ''
      }
      this.paramAPI = {
        act: 'add',
        room_id: null,
        start_use: '',
        end_use: '',
        meeting_type: null,
        meeting_type_other: '',
        number_of_attendance: 0,
        description: '',

        employee_id: '',
        email: '',
        department_id: 0,
        department_name: '',
        company_id: 0,
        company_name: ''
      }
    },
    roomCapacity(id) {
      switch (id) {
        case 1:
          return '9'
        case 2:
          return '6'
        case 3:
          return '5'
        case 4:
          return '7'
        case 5:
          return '7'
        default:
          return ' - '
      }
    }
    // async sendNotifToTelegram(form, bookingNo, createdId) {
    //   const instance = axios.create()
    //   const apiToken = process.env.VUE_APP_TELEGRAM_TOKEN_API_BOT
    //   const chatID = process.env.VUE_APP_ID_CHAT
    //   const msg = `<b>REQUEST PENGGUNAAN RUANG MEETING</b>\n<pre>Request No: ${bookingNo}</pre>\n<pre>PIC: ${
    //     this.getUserProfile.employee.name
    //   }</pre>\n<pre>Email: ${form.email}</pre>\n<pre>Company: ${
    //     form.company_name
    //   }</pre>\n<pre>Department: ${form.department_name}</pre>\n<pre>Room: ${
    //     form.room_name
    //   }</pre>\n<pre>Start Use: ${new Date(form.start_use).toLocaleString('id', {
    //     timeZoneName: 'short'
    //   })}</pre>\n<pre>End Use: ${new Date(form.end_use).toLocaleString('id', {
    //     timeZoneName: 'short'
    //   })}</pre>\n<pre>Meeting Type: ${
    //     form.meeting_type_name
    //   }</pre>\n<pre>Number of Attendance: ${
    //     form.number_of_attendance
    //   }</pre>\n<pre>Description: ${
    //     form.description
    //   }</pre>\n<pre>Status: WAITING APPROVAL HRD</pre>\n\nFYI Guys. Please open this link below to approve. \n👇👇👇👇👇👇\n<a href="https://e-sanqua.sanquawater.co.id/meeting-activity/request-list">https://e-sanqua.sanquawater.co.id/meeting-activity/request-list</a>`
    //   console.log(msg)
    //   await instance.post(
    //     `https://api.telegram.org/bot${apiToken}/sendMessage`,
    //     {
    //       chat_id: chatID,
    //       text: msg,
    //       parse_mode: 'HTML'
    //     }
    //   )
    // }
  }
}
</script>

<style lang="scss" src="">
.booking-form {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .booking-form-card-1 {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 10px 15px 20px 15px;
    width: 95%;
    margin-top: 20px;
    margin-bottom: 10px;
    .booking-form-card-action-2 {
      display: none;
    }
  }
}

@media (max-width: 576px) {
  .booking-form {
    height: 100%;

    .booking-form-card-1 {
      .booking-form-card-action {
        display: none;
      }
      .booking-form-card-action-2 {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
